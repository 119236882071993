import { HomeComponent } from './pages/home/home.component';
import { PropiuRoutes } from '@propiu/interfaces/propiu-route.interface';
import { LayoutComponent } from './layouts/layout/layout.component';

export const appRoutes: PropiuRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: 'pages',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./pages/home/home.component').then((m) => m.HomeComponent),
      },
      {
        path: 'chat',
        loadComponent: () =>
          import('./pages/chat/chat.component').then((m) => m.ChatComponent),
      },
      {
        path: 'customers',
        loadComponent: () =>
          import('./pages/customers/customers.component').then(
            (m) => m.CustomersComponent
          ),
      },
      {
        path: 'exercises',
        loadComponent: () =>
          import('./pages/exercises/exercises.component').then(
            (m) => m.ExercisesComponent
          ),
      },
      {
        path: 'tags',
        loadComponent: () =>
          import('./pages/tags/tags.component').then((m) => m.TagsComponent),
      },
      {
        path: 'help-center',
        loadChildren: () => import('./pages/help-center/help-center.routes'),
      },
      {
        path: 'invoices',
        loadComponent: () =>
          import('./pages/invoices/invoices.component').then(
            (m) => m.InvoicesComponent
          ),
      },
      {
        path: '**',
        loadComponent: () =>
          import('./pages/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
