import { NgModule } from '@angular/core';
import { PropiuHighlightDirective } from './propiu-highlight.directive';
import {
  PROPIU_HIGHLIGHT_OPTIONS,
  PropiuHighlightOptions,
} from './propiu-highlight.model';
/**
 * Import every language you wish to highlight here
 * NOTE: The name of each language must match the file name its imported from
 */
import xml from 'highlight.js/lib/languages/xml';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { PropiuHighlightService } from './propiu-highlight.service';

/**
 * Import every language you wish to highlight here
 * NOTE: The name of each language must match the file name its imported from
 */
export function hljsLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
  ];
}

@NgModule({
  providers: [
    {
      provide: PROPIU_HIGHLIGHT_OPTIONS,
      useValue: {
        languages: hljsLanguages,
      } as PropiuHighlightOptions,
    },
    PropiuHighlightService,
  ],
  imports: [PropiuHighlightDirective],
  exports: [PropiuHighlightDirective],
})
export class PropiuHighlightModule {}
