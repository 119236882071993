<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar text-default w-full px-6 flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="isDesktop$ | async"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="propiu_menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 flex items-center"
  >
    <img alt="Logo" class="w-8 select-none" src="assets/logo/logo.svg" />
    <h1
      [class.hidden]="isDesktop$ | async"
      class="text-2xl font-bold tracking-wide ltr:pl-4 rtl:pr-4 m-0 select-none"
    >
      {{ title$ | async }}
    </h1>
  </a>

  <div
    *ngIf="(isVerticalLayout$ | async) && (isNavbarInToolbar$ | async)"
    [class.hidden]="!(isDesktop$ | async)"
    class="px-6 flex-none flex items-center"
  >
    <propiu-navigation-item
      *ngFor="let item of navigationItems$ | async"
      [item]="item"
    ></propiu-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div *ngIf="config$ | async as setting" class="-mx-1 flex items-center">
    <mat-slide-toggle
      *ngIf="colorScheme$ | async as colorScheme"
      [matTooltip]="
        isDark(colorScheme) ? 'Enable light mode' : 'Enable dark mode'
      "
      (change)="isDark(colorScheme) ? disableDarkMode() : enableDarkMode()"
      [checked]="isDark(colorScheme)"
    >
    </mat-slide-toggle>

    <div
      #userProfileMenuOriginRef
      (click)="openProfileMenu(userProfileMenuOriginRef)"
      [class.propiu-sidenav-user--open]="userMenuOpen$ | async"
      class="propiu-toolbar-user flex items-center"
      matRipple
      matRippleColor="var(--propiu-sidenav-item-ripple-color)"
    >
      <img
        alt="User Avatar"
        class="propiu-toolbar-user__image flex-none object-cover"
        [src]="
          user?.user?.profileImageUrl || '/assets/default-images/profile.png'
        "
      />
      <div class="propiu-toolbar-user__content flex-auto">
        <div class="propiu-toolbar-user__title">
          {{ user?.user?.firstName }} {{ user?.user?.lastName }}
        </div>
        <div class="propiu-toolbar-user__subtitle"></div>
      </div>
      <mat-icon
        class="propiu-toolbar-user__dropdown-icon flex-none"
        svgIcon="chevron_down"
      ></mat-icon>
    </div>
  </div>
</div>

<propiu-navigation
  *ngIf="(isVerticalLayout$ | async) && (isNavbarBelowToolbar$ | async)"
  [class.hidden]="!(isDesktop$ | async)"
></propiu-navigation>
