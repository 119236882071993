import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'propiu-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.registerIcons();
  }

  registerIcons() {
    this.matIconRegistry.addSvgIcon(
      'sidenav_home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/home.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sidenav_chat',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chat.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sidenav_clients',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/clients.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sidenav_exercises',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/exercises.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sidenav_tags',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tags.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sidenav_help',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/help.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sidenav_invoices',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/invoices.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chevron_down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/chevron-down.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'settings_sm',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/settings.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'radio_checked_16',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/radio-checked-16.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'radio_unchecked_16',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/radio-unchecked-16.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'sidenav_search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'close_square',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/close-square.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user_logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/user-logout.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'propiu_menu',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'home_two_tone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/home-two-tone.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'warning_two_tone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/warning-two-tone.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'filter_list',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/filter-list.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'more_horiz',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/more-horiz.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/delete.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chevron_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/chevron-right.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'contacts',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/contacts.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'web',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/web.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'help_text',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/help-text.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'help_media',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/help-media.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'help_divider',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/help-divider.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'help_accordion',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/help-accordion.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'visibility_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/visibility-off.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'visibility',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/visibility.svg'
      )
    );
  }
}
