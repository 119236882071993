<propiu-base-layout *ngIf="config$ | async as config">
  <propiu-progress-bar></propiu-progress-bar>
  <propiu-search></propiu-search>
  <mat-sidenav-container class="propiu-layout-sidenav-container">
    <mat-sidenav
      (closedStart)="onSidenavClosed()"
      [disableClose]="!!(sidenavDisableClose$ | async)"
      [fixedInViewport]="!!(sidenavFixedInViewport$ | async)"
      [mode]="(sidenavMode$ | async) ?? 'side'"
      [opened]="!!(sidenavOpen$ | async)"
    >
      <propiu-sidenav
        [collapsed]="!!(sidenavCollapsed$ | async)"
      ></propiu-sidenav>
    </mat-sidenav>

    <mat-sidenav
      (closedStart)="onQuickpanelClosed()"
      [opened]="!!(quickpanelOpen$ | async)"
      class="propiu-layout-quickpanel"
      mode="over"
      position="end"
    >
      <propiu-quickpanel></propiu-quickpanel>
    </mat-sidenav>

    <mat-sidenav-content class="propiu-layout-sidenav-content">
      <propiu-toolbar
        [class.dark]="config.layout === 'vertical'"
        class="propiu-toolbar"
      ></propiu-toolbar>

      <main class="propiu-layout-content">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</propiu-base-layout>
