import { CSSValue } from '../interfaces/css-value.type';

export enum PropiuTheme {
  DEFAULT = 'propiu-theme-default',
  TEAL = 'propiu-theme-teal',
}

export enum PropiuConfigName {
  apollo = 'apollo',
  zeus = 'zeus',
  hermes = 'hermes',
  poseidon = 'poseidon',
  ares = 'ares',
  ikaros = 'ikaros',
}

export enum PropiuColorScheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface PropiuConfig {
  id: PropiuConfigName;
  name: string;
  bodyClass: string;
  imgSrc: string;
  direction: 'ltr' | 'rtl';
  style: {
    themeClassName: string;
    colorScheme: PropiuColorScheme;
    borderRadius: CSSValue;
    button: {
      borderRadius: CSSValue | undefined;
    };
  };
  layout: 'vertical' | 'horizontal';
  boxed: boolean;
  sidenav: {
    title: string;
    imageUrl: string;
    showCollapsePin: boolean;
    user: {
      visible: boolean;
    };
    search: {
      visible: boolean;
    };
    state: 'expanded' | 'collapsed';
  };
  toolbar: {
    fixed: boolean;
    user: {
      visible: boolean;
    };
  };
  navbar: {
    position: 'below-toolbar' | 'in-toolbar';
  };
  footer: {
    visible: boolean;
    fixed: boolean;
  };
}

export type PropiuConfigs = Record<PropiuConfigName, PropiuConfig>;

export interface PropiuThemeProvider {
  name: string;
  className: string;
}
