import { Injectable } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor() {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'link',
        label: 'Startside',
        route: 'home',
        icon: 'sidenav_home',
        routerLinkActiveOptions: { exact: true },
      },
      {
        type: 'link',
        label: 'Beskeder',
        route: 'chat',
        icon: 'sidenav_chat',
        routerLinkActiveOptions: { exact: true },
      },
      {
        type: 'link',
        label: 'Kunder',
        route: 'customers',
        icon: 'sidenav_clients',
        routerLinkActiveOptions: { exact: true },
      },
      {
        type: 'link',
        label: 'Øvelser',
        route: 'exercises',
        icon: 'sidenav_exercises',
        routerLinkActiveOptions: { exact: true },
      },
      {
        type: 'link',
        label: 'Tags',
        route: 'tags',
        icon: 'sidenav_tags',
        routerLinkActiveOptions: { exact: true },
      },
      {
        type: 'link',
        label: 'Hjælpecenter',
        route: 'help-center',
        icon: 'sidenav_help',
        routerLinkActiveOptions: { exact: true },
      },
      {
        type: 'link',
        label: 'Fakturaer',
        route: 'invoices',
        icon: 'sidenav_invoices',
        routerLinkActiveOptions: { exact: true },
      },
    ]);
  }
}
