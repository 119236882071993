<div
  (mouseenter)="collapseOpenSidenav()"
  (mouseleave)="collapseCloseSidenav()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && (collapsedOpen$ | async)"
  class="propiu-sidenav flex flex-col"
>
  <div class="propiu-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex items-center">
      <img
        [src]="imageUrl$ | async"
        alt="Logo"
        class="w-6 select-none flex-none"
      />
      <h2 class="propiu-sidenav-toolbar__headline flex-auto">
        {{ title$ | async }}
      </h2>
      <button
        (click)="toggleCollapse()"
        *ngIf="showCollapsePin$ | async"
        class="propiu-sidenav-toolbar__collapse-toggle -mr-4 leading-none flex-none hidden lg:block"
        mat-icon-button
        type="button"
      >
        <mat-icon
          *ngIf="!collapsed"
          class="icon-xs"
          svgIcon="radio_checked_16"
          color="primary"
        ></mat-icon>
        <mat-icon
          *ngIf="collapsed"
          class="icon-xs"
          svgIcon="radio_unchecked_16"
          color="primary"
        ></mat-icon>
      </button>
    </div>

    <!-- <div *ngIf="searchVisible$ | async" class="propiu-sidenav-search__container">
      <div
        (click)="openSearch()"
        class="propiu-sidenav-search relative"
        matRipple
        matRippleColor="var(--propiu-sidenav-item-ripple-color)"
      >
        <mat-icon
          class="flex-none propiu-sidenav-search__icon"
          svgIcon="sidenav_search"
        ></mat-icon>
        <div class="flex-auto propiu-sidenav-search__placeholder">
          Quick Search
        </div>
        <div class="flex-none propiu-sidenav-search__keybind">Ctrl K</div>
      </div>
    </div> -->

    <div class="propiu-sidenav-toolbar__divider"></div>
  </div>

  <propiu-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <propiu-sidenav-item
        *ngFor="let item of items$ | async; trackBy: trackByRoute"
        [item]="item"
        [level]="0"
      ></propiu-sidenav-item>
    </div>
  </propiu-scrollbar>
</div>
