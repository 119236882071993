<div class="propiu-user-menu">
  <!-- <a
    (click)="close()"
    [routerLink]="['/apps/social']"
    class="propiu-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--propiu-color-primary-600), 0.1)">
    <mat-icon
      class="propiu-user-menu-item__icon icon-sm"
      svgIcon="mat:account_circle"></mat-icon>
    <div class="propiu-user-menu-item__label">Your Profile</div>
  </a>

  <a
    (click)="close()"
    [routerLink]="['/']"
    class="propiu-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--propiu-color-primary-600), 0.1)">
    <mat-icon
      class="propiu-user-menu-item__icon icon-sm"
      svgIcon="mat:insights"></mat-icon>
    <div class="propiu-user-menu-item__label">Analytics</div>
    <div class="propiu-user-menu-item__badge">NEW</div>
  </a>

  <a
    (click)="close()"
    [routerLink]="['/apps/social/timeline']"
    class="propiu-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--propiu-color-primary-600), 0.1)">
    <mat-icon
      class="propiu-user-menu-item__icon icon-sm"
      svgIcon="settings_sm"></mat-icon>
    <div class="propiu-user-menu-item__label">Account Settings</div>
  </a>

  <div class="border-b border-divider mx-4"></div>

  <div
    class="propiu-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--propiu-color-primary-600), 0.1)">
    <mat-icon
      class="propiu-user-menu-item__icon icon-sm"
      svgIcon="mat:switch_account"></mat-icon>
    <div class="propiu-user-menu-item__label">Switch Account</div>
    <mat-icon
      class="propiu-user-menu-item__icon icon-sm"
      svgIcon="chevron_right"></mat-icon>
  </div> -->

  <a
    (click)="close()"
    [routerLink]="['/login']"
    class="propiu-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--propiu-color-primary-600), 0.1)"
  >
    <mat-icon
      class="propiu-user-menu-item__icon icon-sm"
      svgIcon="user_logout"
    ></mat-icon>
    <div class="propiu-user-menu-item__label">Sign Out</div>
  </a>
</div>
