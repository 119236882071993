import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    let isApiUrl =
      request.url.includes("v1/authenticate/login") ||
      request.url.includes("/v1/authenticate/register/admin") ||
      request.url.includes("/v1/password/reset") ||
      request.url.includes("/v1/password");

    if (request.url.includes("/v1/password/force")) {
      isApiUrl = false;
    }

    if (!isApiUrl && user) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${user.token}` },
      });
    }

    return next.handle(request);
  }
}
