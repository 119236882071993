import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  Provider,
} from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { PropiuSplashScreenService } from './services/propiu-splash-screen.service';
import { PropiuLayoutService } from './services/propiu-layout.service';
import { PropiuPlatformService } from './services/propiu-platform.service';
import { PropiuHighlightModule } from './components/propiu-highlight/propiu-highlight.module';
import { PROPIU_CONFIG, PROPIU_THEMES } from './config/config.token';
import {
  PropiuConfig,
  PropiuThemeProvider,
} from './config/propiu-config.interface';
import { PropiuDemoService } from './services/propiu-demo.service';

export function providePropiu(options: {
  config: PropiuConfig;
  availableThemes: PropiuThemeProvider[];
}): (Provider | EnvironmentProviders)[] {
  return [
    importProvidersFrom(PropiuHighlightModule),
    {
      provide: PROPIU_CONFIG,
      useValue: options.config,
    },
    {
      provide: PROPIU_THEMES,
      useValue: options.availableThemes,
    },
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: {
        doctype: true,
        theme: false,
        version: true,
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      } satisfies MatFormFieldDefaultOptions,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(PropiuSplashScreenService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(PropiuLayoutService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(PropiuPlatformService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(PropiuDemoService),
      multi: true,
    },
  ];
}
