<div
  *ngIf="config$ | async as config"
  [class.propiu-layout-boxed]="config.boxed"
  [class.propiu-layout-footer-enabled]="isFooterVisible$ | async"
  [class.propiu-layout-footer-fixed]="
    config.footer.fixed && (isFooterVisible$ | async)
  "
  [class.propiu-layout-horizontal]="config.layout === 'horizontal'"
  [class.propiu-layout-scroll-disabled]="scrollDisabled$ | async"
  [class.propiu-layout-search-overlay-open]="searchOpen$ | async"
  [class.propiu-layout-sidenav-collapsed]="sidenavCollapsed$ | async"
  [class.propiu-layout-toolbar-fixed]="config.toolbar.fixed"
  [class.propiu-layout-vertical]="config.layout === 'vertical'"
  class="propiu-base-layout-container"
>
  <ng-content></ng-content>
</div>
