import { mergeDeep } from '../utils/merge-deep';
import {
  PropiuColorScheme,
  PropiuConfig,
  PropiuConfigName,
  PropiuConfigs,
  PropiuTheme,
} from './propiu-config.interface';
import deepClone from '@propiu/utils/deep-clone';

const baseConfig: PropiuConfig = {
  id: PropiuConfigName.apollo,
  name: 'Apollo',
  bodyClass: 'propiu-layout-apollo',
  style: {
    themeClassName: PropiuTheme.DEFAULT,
    colorScheme: PropiuColorScheme.LIGHT,
    borderRadius: {
      value: 0.5,
      unit: 'rem',
    },
    button: {
      borderRadius: {
        value: 9999,
        unit: 'px',
      },
    },
  },
  direction: 'ltr',
  imgSrc: '',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'Propiu',
    imageUrl: 'assets/logo/logo.svg',
    showCollapsePin: true,
    user: {
      visible: true,
    },
    search: {
      visible: true,
    },
    state: 'expanded',
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true,
    },
  },
  navbar: {
    position: 'below-toolbar',
  },
  footer: {
    visible: true,
    fixed: true,
  },
};

export const propiuConfigs: PropiuConfigs = {
  apollo: baseConfig,
  poseidon: mergeDeep(deepClone(baseConfig), {
    id: PropiuConfigName.poseidon,
    name: 'Poseidon',
    bodyClass: 'propiu-layout-poseidon',
    imgSrc: '',
    sidenav: {
      user: {
        visible: true,
      },
      search: {
        visible: true,
      },
    },
    toolbar: {
      user: {
        visible: false,
      },
    },
  }),
  hermes: mergeDeep(deepClone(baseConfig), {
    id: PropiuConfigName.hermes,
    name: 'Hermes',
    bodyClass: 'propiu-layout-hermes',
    imgSrc: '',
    layout: 'vertical',
    boxed: true,
    sidenav: {
      user: {
        visible: false,
      },
      search: {
        visible: false,
      },
    },
    toolbar: {
      fixed: false,
    },
    footer: {
      fixed: false,
    },
  }),
  ares: mergeDeep(deepClone(baseConfig), {
    id: PropiuConfigName.ares,
    name: 'Ares',
    bodyClass: 'propiu-layout-ares',
    imgSrc: '',
    sidenav: {
      user: {
        visible: false,
      },
      search: {
        visible: false,
      },
    },
    toolbar: {
      fixed: false,
    },
    navbar: {
      position: 'in-toolbar',
    },
    footer: {
      fixed: false,
    },
  }),
  zeus: mergeDeep(deepClone(baseConfig), {
    id: PropiuConfigName.zeus,
    name: 'Zeus',
    bodyClass: 'propiu-layout-zeus',
    imgSrc: '',
    sidenav: {
      state: 'collapsed',
    },
  }),
  ikaros: mergeDeep(deepClone(baseConfig), {
    id: PropiuConfigName.ikaros,
    name: 'Ikaros',
    bodyClass: 'propiu-layout-ikaros',
    imgSrc: '',
    layout: 'vertical',
    boxed: true,
    sidenav: {
      user: {
        visible: false,
      },
      search: {
        visible: false,
      },
    },
    toolbar: {
      fixed: false,
    },
    navbar: {
      position: 'in-toolbar',
    },
    footer: {
      fixed: false,
    },
  }),
};
